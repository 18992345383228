//	@flow

import { createCampaign, getCampaigns, getCampaignsStatus, getDetailedCampaigns, removeCampaign, removeSpaceFromCampaign, renameCampaign, sendQuotationRequest, updateContractStatus } from '../api/campaigns';
import { MiddlewareRegistry } from '../redux';

import { openDialog } from '../dialog';
import { NoticeDialog, setToastMessage, setToastVisible } from '../notifications';

import { CREATE_CAMPAIGN, GET_CAMPAIGNS, GET_CAMPAIGNS_STATUS, GET_DETAILED_CAMPAIGNS_LIST, REMOVE_CAMPAIGN, REMOVE_CONTRACT_FROM_CAMPAIGN, RENAME_CAMPAIGN, REQUEST_QUOTATION, UPDATE_CONTRACT_STATUS } from './actionTypes';
import { setCampaigns, setCampaignsStatus, setDetailedCampaigns, setDisplayingNewCampaignInput } from './actions';


MiddlewareRegistry.register(store => next => action => {


	switch (action.type) {


		case CREATE_CAMPAIGN:
			return _createCampaign(store, next, action);


		case GET_CAMPAIGNS:
			return _getCampaigns(store, next, action);


		case GET_CAMPAIGNS_STATUS:
			return _getCampaignsStatus(store, next, action);


		case GET_DETAILED_CAMPAIGNS_LIST:
			return _getDetailedCampaignsList(store, next, action);


		case REMOVE_CAMPAIGN:
			return _removeCampaign(store, next, action);


		case RENAME_CAMPAIGN:
			return _renameCampaign(store, next, action);


		case REQUEST_QUOTATION:
			return _requestQuotation(store, next, action);


		case REMOVE_CONTRACT_FROM_CAMPAIGN:
			return _removeSpaceFromCampaign(store, next, action);


		case UPDATE_CONTRACT_STATUS:
			return _updateContractStatus(store, next, action);

		default:
			return(next(action));


	}

});


async function _createCampaign(store, next, action){

	const { dispatch } = store;

	const { campaignName, token } = action.params;

	try {

		let createCampaignResponse = await createCampaign(token, campaignName).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error agregando campaña: No pudimos comunicarnos con el servidor. Verifique que hay una conexión activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

		let { err, message, status } = createCampaignResponse.data;

		console.log(createCampaignResponse.data);

		if(err || status === 'Error'){

			console.log(createCampaignResponse.data);

			dispatch(setToastMessage(`Error creando campaña: ${ err || message }`));
			return dispatch(setToastVisible(true));

		}

		dispatch(setDisplayingNewCampaignInput(false));
		dispatch(setToastMessage(`Campaña ${ campaignName } creada exitosamente.`));
		dispatch(setToastVisible(true));

		return _getCampaigns(store, next, action);


	} catch(e) {

		console.error(e);

		dispatch(setToastMessage(`Error agregando a favoritos: ${ e.message }.`));
		return dispatch(setToastVisible(true));

	}

}


async function _getCampaigns(store, next, action){

	const { dispatch } = store;

	const { token } = action.params;

	try {

		let getCampaignsListResponse = await getCampaigns(token).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err, campaigns } = getCampaignsListResponse.data;

		if(err){

			console.log(getCampaignsListResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error obteniendo el listado de campañas.',
				modalMessage: `${ err }`
			}));

		}

		next(action);

		return dispatch(setCampaigns(campaigns));

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}




async function _getCampaignsStatus(store, next, action){

	const { dispatch } = store;

	const { token } = action;

	try {

		let campaignsStatusResponse = await getCampaignsStatus(token).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err, statusList } = campaignsStatusResponse.data;

		if(err){

			console.log(campaignsStatusResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error obteniendo el listado de estatus de espacios de campanas.',
				modalMessage: `${ err }`
			}));

		}

		next(action);

		return dispatch(setCampaignsStatus(statusList));

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}



async function _getDetailedCampaignsList(store, next, action){

	const { dispatch } = store;

	const { token } = action.params ? action.params : action;


	try {

		let getDetailedCampaignsResponse = await getDetailedCampaigns(token).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err, campaigns } = getDetailedCampaignsResponse.data;

		if(err){

			console.log(getDetailedCampaignsResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error obteniendo el listado de campañas.',
				modalMessage: `${ err }`
			}));

		}

		next(action);

		return dispatch(setDetailedCampaigns(campaigns));

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}



async function _removeCampaign(store, next, action){

	const { dispatch } = store;

	const { token, campaignId } = action.params;


	try {

		let removeCampaignResponse = await removeCampaign(token, campaignId).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err } = removeCampaignResponse.data;

		if(err){

			console.log(removeCampaignResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error solicitando cotizacion.',
				modalMessage: `${ err }`
			}));

		}

		return _getDetailedCampaignsList(store, next, action);

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}



async function _removeSpaceFromCampaign(store, next, action){

	const { dispatch } = store;

	const { token, contractId } = action.params;


	try {

		let removeSpaceFromCampaignResponse = await removeSpaceFromCampaign(token, contractId).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err } = removeSpaceFromCampaignResponse.data;

		if(err){

			console.log(removeSpaceFromCampaignResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error solicitando cotizacion.',
				modalMessage: `${ err }`
			}));

		}

		return _getDetailedCampaignsList(store, next, action);

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}


async function _renameCampaign(store, next, action){

	const { dispatch } = store;

	const { token, campaignName, campaignId } = action.params;


	try {

		let renameCampaignResponse = await renameCampaign(token, campaignName, campaignId).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err } = renameCampaignResponse.data;

		if(err){

			console.log(renameCampaignResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error renombrando campaña.',
				modalMessage: `${ err }`
			}));

		}

		return _getDetailedCampaignsList(store, next, action);

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al renombrar campaña.',
			modalMessage: `${ e }`
		}));

	}

}


async function _requestQuotation(store, next, action){

	const { dispatch } = store;

	const { token, startDate, endDate, contractId } = action.params;


	try {

		let requestQuotationResponse = await sendQuotationRequest(token, startDate, endDate, contractId).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err } = requestQuotationResponse.data;

		if(err){

			console.log(requestQuotationResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error solicitando cotizacion.',
				modalMessage: `${ err }`
			}));

		}

		return _getDetailedCampaignsList(store, next, action);

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}


async function _updateContractStatus(store, next, action){

	const { dispatch } = store;

	const { token, contractId, contractStatus } = action.params;


	try {

		let updateStatusResponse = await updateContractStatus(token, contractId, contractStatus).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err } = updateStatusResponse.data;

		if(err){

			console.log(updateStatusResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error actualizando contrato en campaña.',
				modalMessage: `${ err }`
			}));

		}

		return _getDetailedCampaignsList(store, next, action);

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener campañas.',
			modalMessage: `${ e }`
		}));

	}

}

// @flow
import React, { Component } from 'react';

import { Button, Container, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import type { Dispatch } from 'redux';

import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setCurrentScreen } from '../../app/actions';
import { registerUser } from '../../auth/actions';
import { connect } from '../../redux';


const styles = theme => ({

	container: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},

	grow: {
		flexGrow: 1
	},

	headerRight: {
		display: 'flex'
	},

	textField: {
		marginBottom: '1.5rem'
	}

});


type Props = {
	dispatch: Dispatch
}


class Registration extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			isPasswordBeingDisplayed: false,
			username: '',
			password: '',
			name: '',
			lastname: ''
		}

		this._inputChanged = this._inputChanged.bind(this);
		this._loadLoginForm = this._loadLoginForm.bind(this);
		this._storeUser = this._storeUser.bind(this);
		this._togglePasswordVisibility = this._togglePasswordVisibility.bind(this);

	}


	render(){

		const { classes } = this.props;

		return (

			<Container
				maxWidth='xs'
				className={ classes.container }
			>
				<h3>Registro</h3>

				<form noValidate autoComplete="off">

					<TextField
						className={ classes.textField }
						fullWidth
						id="username"
						label="Correo electronico (Éste será tu Usuario)"
						onChange={ this._inputChanged }
						value={ this.state.username }
						variant="outlined" />


					<TextField
						className={ classes.textField }

						fullWidth
						id="password"
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									<IconButton
										aria-label="Visibilidad de la contraseña"
										onClick={ this._togglePasswordVisibility }
										>
										{ this.state.isPasswordBeingDisplayed ? <VisibilityOff /> : <Visibility /> }
									</IconButton>
								</InputAdornment>

						}}
						label="Contrasena"
						onChange={ this._inputChanged }
						value={ this.state.password }
						type={ this.state.isPasswordBeingDisplayed ? "text" : "password" }
						variant="outlined" />

					<TextField
						className={ classes.textField }
						fullWidth
						id="name"
						label="Nombre(s)"
						onChange={ this._inputChanged }
						value={ this.state.name }
						variant="outlined" />


					<TextField
						className={ classes.textField }
						fullWidth
						id="lastname"
						label="Apellidos"
						onChange={ this._inputChanged }
						value={ this.state.lastname }
						variant="outlined" />

					<Button variant="contained" color="secondary" className={ classes.actionBtn } onClick={ this._storeUser }>Registrarme</Button>

				</form>

				<p>Ya tienes cuenta de usuario? <Button color="secondary" onClick={ this._loadLoginForm }>Ingresa aquí</Button></p>


			</Container>

		);

	}


	_inputChanged(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}


	_loadLoginForm(){

		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

	}

	_storeUser(){

		const user = {
			username: this.state.username,
			password: this.state.password,
			name: this.state.name,
			lastname: this.state.lastname
		}

		this.props.dispatch(registerUser(user));

	}

	_togglePasswordVisibility(){

		const passwordVisibility = !this.state.isPasswordBeingDisplayed;

		this.setState({
			isPasswordBeingDisplayed: passwordVisibility
		});

	}

}


function abstractMapStateToProps(){
	return { };
}


export default withStyles(styles)(connect(abstractMapStateToProps)(Registration));

// @flow
import React, { Component } from 'react';

import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import type { Dispatch } from 'redux';

import { SPACES_SCREENS } from '../../mySpaces/constants';
import { setMySpacesScreen } from '../../mySpaces/actions';
import { connect } from '../../redux';

import SpaceContract from './spaceContract';


const styles = theme => ({

	addButton: {
		borderWidth: '2px',
		fontSize: '1.5rem',
		marginTop: '1rem',
		padding: '0.5rem 1rem',
		position: 'sticky',
		top: '39rem'
	},

	mapContainer:{
		backgroundColor: "#cccccc",
		height:'50vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '8px',
		position: 'sticky !important',
		top: '7rem',
		width:'97%',

	},

});


type Props = {
	_currentMySpacesScreen: Number,
	_detailedCampaigns: Array<Object>,
	_editingCampaignIndex: Number,
	dispatch: Dispatch
}


class SpaceContracts extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			mapMarkers: [ ]
		}

		this._addMarkerToMap = this._addMarkerToMap.bind(this);
		this._displaySearchIframe = this._displaySearchIframe.bind(this);

	}


	componentDidMount(){

		const { _detailedCampaigns, _editingCampaignIndex } = this.props;

		const _editingCampaignSpaces = _detailedCampaigns[_editingCampaignIndex].spaces;

		_editingCampaignSpaces.length > 0 &&
		window.subscribeToMapa(() => {

			this.google = window.google;

			var mapOptions = {
				zoom: 13,
				disableDoubleClickZoom: true,
				draggable: false,
				scrollwheel: false,
				panControl: false,
				center: new this.google.maps.LatLng( 25.444348 , -100.977792  ),

				disableDefaultUI:true
			};

			this.mapa_ = new this.google.maps.Map( document.getElementById('canvasMapa') ,mapOptions);

		});

	}


	static getDerivedStateFromProps(props, state){

		return { }

	}



	render(){

		const { _currentMySpacesScreen, _detailedCampaigns, _editingCampaignIndex, classes } = this.props;

		const _editingCampaignSpaces = _detailedCampaigns[_editingCampaignIndex].spaces;


		return (

			<Grid container>

				<Grid item xs={ 7 }>

					<Grid container spacing={ 2 } >

						{
							_editingCampaignSpaces.length === 0 &&
							<Grid item style={{ marginTop: '5rem', textAlign: 'center' }} xs={ 12 }>
								No se han agregado espacios a esta campa&ntilde;a
							</Grid>
						}

						{
							_editingCampaignSpaces.map((contractDetails, index) =>

								<Grid item key={ `contract_${ index }` } xs={ 12 }>

									<SpaceContract contract={ contractDetails } key={ `space_contract_${ index }` } setMarker={ this._addMarkerToMap } />

								</Grid>

							)
						}

					</Grid>

				</Grid>

				<Grid item xs={ 5 }>

					{
						_editingCampaignSpaces.length > 0 &&

						<div id="canvasMapa" className={ classes.mapContainer }/>

					}

					{
						_currentMySpacesScreen === SPACES_SCREENS.CAMPAIGN_CONTRACTS &&
						<Button className={ classes.addButton } size="small" variant="outlined" color="primary" onClick={ this._displaySearchIframe }>Agregar espacios a esta campa&ntilde;a</Button>
					}



				</Grid>


			</Grid>

		);

	}


	_addMarkerToMap(name, lat, lng){

		const { mapMarkers } = this.state;

		const existingMarker = mapMarkers.find(el => el.title === name);


		if(!existingMarker){

			const google = this.google;

			const position = new google.maps.LatLng(lat, lng);

			const marker = new google.maps.Marker({
				position,
				title: name,
				map: this.mapa_
			});

			this.setState({
				mapMarkers: [ ...this.state.mapMarkers, marker ]
			}, () => {

				const bounds = new google.maps.LatLngBounds();

				this.state.mapMarkers.map(marker =>

					bounds.extend({
						lat: marker.position.lat(),
						lng: marker.position.lng()
					})

				);

				this.mapa_.fitBounds(bounds);

				if(this.mapa_.getZoom() > 17)
					this.mapa_.setZoom(17);

			});

		}



	}


	_displaySearchIframe(){

		this.props.dispatch(setMySpacesScreen(SPACES_SCREENS.SPACES_BROWSING));

		const container = document.getElementById('spacesContainer');
		container.scrollTop = 0;

	}


}


function _mapStateToProps(state){

	const { detailedCampaigns, editingCampaignIndex } = state['base/campaigns'];
	const { currentMySpacesScreen } = state['base/mySpaces'];

	return {
		_currentMySpacesScreen: currentMySpacesScreen,
		_detailedCampaigns: detailedCampaigns,
		_editingCampaignIndex: editingCampaignIndex
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(SpaceContracts));

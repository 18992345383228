import React, { Component } from 'react';
import { Container } from '@material-ui/core';

import type { Dispatch } from 'redux';

import { AddToCampaignDialog } from '../../favorites';
import { connect } from '../../redux';


type Props = {
	_displayAddToCampaign: Boolean,
	_iframePath: String,
	dispatch: Dispatch
}

class Home extends Component<Props> {

	constructor(props){

		super(props);

		this.state = {
			innerWidth: 1024,
			height: 768
		}

		this._onResize = this._onResize.bind(this);

	}


	componentDidMount(){

		this._onResize();

		window.addEventListener('resize', this._onResize);

	}


	componentWillUnmount(){

		window.removeEventListener('resize', this._onResize);

	}


	render(){

		// const { innerWidth, innerHeight } = window;
		//
		// const height = innerHeight - window.convertRemToPixels(3.5) - 50;

		const { _iframePath } = this.props;
		const { innerWidth, height } = this.state;

		return (

			<Container disableGutters maxWidth={ false } style={{ height: height }}>

				<iframe src={ `https://adxpace.com/spaces${ _iframePath }` } width={ `${ innerWidth }` } height={ height }  title="spaces"></iframe>

				{
					this.props._displayAddToCampaign && <AddToCampaignDialog />
				}

			</Container>
		)


	}


	_onResize(){

		const { innerWidth, innerHeight } = window;

		const height = innerHeight - window.convertRemToPixels(3.5) - 40;

		this.setState({
			innerWidth: innerWidth,
			height: height
		});

	}

}


function _mapStateToProps(state){

	const { displayAddToCampaign } = state['base/campaigns'];
	const { iframePath } = state['base/home'];

	return {
		_displayAddToCampaign: displayAddToCampaign,
		_iframePath: iframePath
	}


}


export default connect(_mapStateToProps)(Home);

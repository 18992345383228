/**
 * The type of (redux) action which sets whether the APP is starting.
 *
 * {
 *     type: APP_WILL_MOUNT,
 *     userLoggedIn: boolean
 * }
 */
export const APP_WILL_MOUNT = 'APP_WILL_MOUNT';


/**
 * The type of (redux) action which sets whether the APP is going to be destroyed.
 *
 * {
 *     type: APP_WILL_UNMOUNT,
 *     userLoggedIn: boolean
 * }
 */
export const APP_WILL_UNMOUNT = 'APP_WILL_UNMOUNT';


export const SET_CURRENT_POST = 'SET_CURRENT_POST';


/**
 * The type of (redux) action which sets the current displaying screen.
 *
 * {
 *     type: SET_CURRENT_SCREEN,
 *     currentScreen: number
 * }
 */
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';


export const SET_LAST_SEARCH_TERM = 'SET_LAST_SEARCH_TERM';

// @flow
import React, { Component } from 'react';

import { Button, Container, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { getCampaigns, setDisplayAddToCampaign, NewCampaignForm } from '../../campaigns';
import { connect } from '../../redux';

import { addToCampaign, setSelectedFavorite, setSelectedSpaceName } from '../actions';


type Props = {
	_campaigns: Array<Object>,
	_selectedfavorite: Object,
	_selectedSpaceName: String,
	_userAuth: Object,
	dispatch: Dispatch

}

const styles = theme => ({

	root: {
		backgroundColor: '#fdfdfd',
		display: 'flex',
		height: '11.7rem',
		marginTop: '0.3rem',
		'&:hover':{
			backgroundColor: '#f7f7f7',
		}
	},
	buttonsContainer:{
		margin: '1.3rem 0.7rem'
	},
	content: {
		flex: '1 0 auto',
		margin: '1rem',
		textAlign: 'left'
	},
	contentContainer:{
		backgroundColor: '#ffffff',
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},
	cover: {
		backgroundSize: 'contain',
		margin: '0.5rem',
		width: '33%',
	},
	dangerButton: {
		background: red[900],
		color: 'white',
		marginRight: '1rem'
	},

	mainContainer: {
		backgroundColor: 'rgba(250, 250, 250, 0.7)',
		border: 'solid 1px #333',
		height: '100%',
		left: 0,
		margin: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1400
	},

	successBtn: {
		background: green[800],
		color: 'white',
		'&:hover': {
			background: green[900],
		}
	},

});



class AddToCampaignDialog extends Component<Props> {

	constructor(props){

		super(props);

		this._addToCampaign = this._addToCampaign.bind(this);
		this._hideMe = this._hideMe.bind(this);

	}


	componentDidMount(){

		const { _userAuth, dispatch } = this.props;

		dispatch(getCampaigns({ token: _userAuth.token }));

	}


	render(){

		const { _campaigns, classes } = this.props;


		return (

			<div className={ classes.mainContainer }>

				<Container className={ classes.contentContainer } maxWidth="sm">

					{
						_campaigns.length ?

						<>
							<h4>Agregar a campa&ntilde;a</h4>

							<List component="nav" aria-label="campañas" style={{ marginBottom: '1.3rem' }}>

								{
									_campaigns.map((campaign, i) => (

										<div key={`campaign_${ i }`}>
											<ListItem button onClick={ e => this._addToCampaign(campaign.id) }>
												<ListItemText primary={ campaign.campaignName } />
											</ListItem>
											{
												i < _campaigns.length -1 && <Divider />
											}
										</div>

									))
								}

							</List>
						</>

						:

						<h3>A&uacute;n no se han creado campa&ntilde;as</h3>
					}


					<NewCampaignForm />

					<Button color="primary" onClick={ this._hideMe } variant="contained">Cerrar</Button>

				</Container>

			</div>

		);

	}


	_addToCampaign(campaignId){

		const { _selectedfavorite, _selectedSpaceName, _userAuth, dispatch } = this.props;

		if(_selectedfavorite){
			dispatch(addToCampaign({
				adId: _selectedfavorite.postId,
				campaignId: campaignId,
				token: _userAuth.token
			}));
		} else {
			dispatch(addToCampaign({
				adTitle: _selectedSpaceName,
				campaignId: campaignId,
				token: _userAuth.token
			}));
		}

		this._hideMe();

	}


	_hideMe(){

		this.props.dispatch(setDisplayAddToCampaign(false));
		this.props.dispatch(setSelectedFavorite(undefined));
		this.props.dispatch(setSelectedSpaceName(undefined));

	}

}


function _mapStateToProps(state){

	const { campaigns } = state['base/campaigns'];
	const { userAuth } = state['base/auth'];
	const { selectedFavorite, selectedSpaceName } = state['base/favorites'];

	return {
		_campaigns: campaigns,
		_selectedfavorite: selectedFavorite,
		_selectedSpaceName: selectedSpaceName,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(AddToCampaignDialog));

// @flow
import React, { Component } from 'react';

import { Button, Container, Typography  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { AddToCampaignDialog } from '../../favorites';
import { setIframePath } from '../../home/actions';
import { SPACES_SCREENS } from '../../mySpaces/constants';
import { setMySpacesScreen } from '../../mySpaces/actions';
import { connect } from '../../redux';

import SpaceContracts from './spaceContracts';


const styles = theme => ({

	backToCampaignBtn: {
		color: '#eee',
		fontSize: '0.9rem',
		marginLeft: '11rem'
	},

	campaignHeader: {
		backgroundColor: '#050505',
		borderBottomLeftRadius: '1rem',
		borderBottomRightRadius: '1rem',
		color: '#eee',
		fontSize: '1.3rem',
		fontWeight: '400',
		marginBottom: '0.7rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingBottom: '0.3rem',
		paddingTop: '0.3rem',
		position: 'sticky',
		textAlign: 'center',
		top: '4rem',
		width: '83%',
		zIndex: '2'
	}
});


type Props = {
	_currentMySpacesScreen: Number,
	_detailedCampaigns: Array<Object>,
	_displayAddToCampaign: Boolean,
	_editingCampaignIndex: Number,
	_lastSearchTerm: String,
	_userAuth: Object,

	dispatch: Dispatch
}


class Campaign extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			innerWidth: 1024,
			height: 768,
			lastSearchTerm: ''
		}

		this._displayContracts = this._displayContracts.bind(this);
		this._gotoSearch = this._gotoSearch.bind(this);
		this._onResize = this._onResize.bind(this);

	}


	componentDidMount(){

		this._onResize();

		window.addEventListener('resize', this._onResize);

		if(this.props._lastSearchTerm){

			this.setState({
				lastSearchTerm: this.props._lastSearchTerm
			});

		}

	}



	componentWillUnmount(){

		window.removeEventListener('resize', this._onResize);

	}



	render(){

		const { _currentMySpacesScreen, _detailedCampaigns, _editingCampaignIndex, classes } = this.props;

		const _currentEditingCampaign = _detailedCampaigns?.length && _editingCampaignIndex > -1 ? _detailedCampaigns[_editingCampaignIndex] : undefined;

		const { innerWidth, height, lastSearchTerm } = this.state;

		return (

			<Container maxWidth="xl">

				{
					_editingCampaignIndex !== undefined && _currentEditingCampaign &&
					<>
						<Typography className={ classes.campaignHeader } variant="h5"  align="left" color="textPrimary" >
							Editando campa&ntilde;a &nbsp; <strong>{ _currentEditingCampaign.campaignName }</strong>
							{ _currentMySpacesScreen === SPACES_SCREENS.SPACES_BROWSING && <Button className={ classes.backToCampaignBtn } onClick={ this._displayContracts }>Ocultar b&uacute;squeda de espacios</Button> }
							<Button className={ classes.backToCampaignBtn } onClick={ this._gotoSearch }>Cerrar campaña</Button>
						</Typography>

						{ _currentMySpacesScreen === SPACES_SCREENS.CAMPAIGN_CONTRACTS && <SpaceContracts /> }

						{
							_currentMySpacesScreen === SPACES_SCREENS.SPACES_BROWSING &&

							<>

								<iframe src={ `https://adxpace.com/spaces/index.php/adxpace-resultados-busqueda/${ lastSearchTerm }` } width={ `${ innerWidth }` } height={ height }  title="spaces"></iframe>

								<SpaceContracts />

								{
									this.props._displayAddToCampaign && <AddToCampaignDialog />
								}

							</>

						}



					</>
				}

			</Container>

		);

	}



	_displayContracts(){

		this.props.dispatch(setMySpacesScreen(SPACES_SCREENS.CAMPAIGN_CONTRACTS));

	}


	_gotoSearch(){

		this.props.history?.push('/');
		this.props.dispatch(setIframePath('/index.php/adxpace-resultados-busqueda/'));
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

	}



	_onResize(){

		const { innerWidth, innerHeight } = window;

		const height = innerHeight - window.convertRemToPixels(3.5) - 40;

		this.setState({
			innerWidth: innerWidth - 48,
			height: height
		});

	}

}



function _mapStateToProps(state){

	const { lastSearchTerm } = state['base/app'];
	const { userAuth } = state['base/auth'];
	const { detailedCampaigns, displayAddToCampaign, editingCampaignIndex } = state['base/campaigns'];
	const { currentMySpacesScreen } = state['base/mySpaces'];


	return {
		_currentMySpacesScreen: currentMySpacesScreen,
		_detailedCampaigns: detailedCampaigns,
		_displayAddToCampaign: displayAddToCampaign,
		_editingCampaignIndex: editingCampaignIndex,
		_lastSearchTerm: lastSearchTerm,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(Campaign));

import axios from 'axios';

import { settings } from './settings';

const SERVER_URL = settings.endpoint;


function login(user){

	const params = new URLSearchParams();

	const { username, password } = user;

	params.append('username', username);
	params.append('password', password);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	}

	return axios.post(`${ SERVER_URL }/users/login`, params, config);

}


function register(user){

	const params = new URLSearchParams();

	const { username, password, name, lastname } = user;

	params.append('username', username);
	params.append('password', password);
	params.append('name', name);
	params.append('lastname', lastname);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',

		}
	}

	return axios.post(`${ SERVER_URL }/users/create`, params, config);

}


function requestPasswordReset(username){

	const params = new URLSearchParams();

	params.append('username', username);

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	}

	return axios.post(`${ SERVER_URL }/users/requestPasswordReset`, params, config);

}



function submitNewPassword(token, password){

	const params = new URLSearchParams();

	params.append('password', password);
	params.append('token', token);

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	}

	return axios.post(`${ SERVER_URL }/users/setUserPassword`, params, config);

}


export { login, register, requestPasswordReset, submitNewPassword };

// @flow

import { SET_CURRENT_POST, SET_CURRENT_SCREEN, SET_LAST_SEARCH_TERM } from './actionTypes';



/**
 * Sets the current displaying screen.
 *
 * @param {number} currentScreen.
 * @returns {{
 *     type: SET_CURRENT_SCREEN,
 *     currentScreen: number
 * }}
 */
export function setCurrentScreen(currentScreen){

    return {
        type: SET_CURRENT_SCREEN,
        currentScreen
    }

}


export function setCurrentPost(currentPost){

	return {
		type: SET_CURRENT_POST,
		currentPost
	}

}


export function setLastSearchTerm(lastSearchTerm){

	return {
		type: SET_LAST_SEARCH_TERM,
		lastSearchTerm
	}

}

//  @flow
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import type { Dispatch } from 'redux';


import { connect } from '../../redux';

import { CURRENT_DISPLAYING_SCREEN } from '../constants';
import { DialogContainer } from '../../dialog';
import { setDisplayAddToCampaign } from '../../campaigns';
import { addToCampaignFromCampaign, addToFavorites, setSelectedSpaceName } from '../../favorites/actions';
import { Footer } from '../../footer';
import { Header } from '../../header';
import { Home } from '../../home';
import { ForgotPassword, Login, PasswordReset } from '../../login';
import { MySpaces } from '../../mySpaces';
import { Registration } from '../../registration';
import { Toast } from '../../notifications';

import { setCurrentPost, setCurrentScreen, setLastSearchTerm } from '../actions';

import '../styles/App.css';



type Props = {

	_currentScreen: Number,
	_userAuth: Object,
	_userData: Object,
	_userLoggedIn: Boolean,


	/**
	* The redux {@code dispatch} function.
	*/
	dispatch: Dispatch<any>

}

class App extends Component<Props> {


    installPrompt = null;


	constructor(props: Props){

		super(props);



		this._getCurrentScreen = this._getCurrentScreen.bind(this);
		this._onMessageReceived = this._onMessageReceived.bind(this);

		window.addEventListener("message", e => this._onMessageReceived(e), false);

    }



    componentDidMount(){

    }



	_getCurrentScreen(){

	    switch (this.props._currentScreen) {

				case CURRENT_DISPLAYING_SCREEN.LOGIN:
					return <Login />


				case CURRENT_DISPLAYING_SCREEN.REGISTRATION:
					return <Registration />


				case CURRENT_DISPLAYING_SCREEN.CONTROL_PANEL_USER:
					return <MySpaces />


				case CURRENT_DISPLAYING_SCREEN.WELCOME:
					return <Home />

				case CURRENT_DISPLAYING_SCREEN.FORGOT_PASSWORD:
					return <ForgotPassword />


	        default:
					console.log(`Screen still not active.`);
					return <Home key={ 'home' } />
					// return null;

	    }

    }





    render(){

        return(

				<Router>
					<div className="App" id="main-container">
						<Header userData={ this.props._userData } logout={ this.destroySession } />

							<Switch>
								<Route exact path={["/resetPassword/:token", '/testing/resetPassword/:token']} component={ PasswordReset } />
								<Route render={ () => this._getCurrentScreen() } />
							</Switch>

						<Footer />




					</div>
					<DialogContainer />
					<Toast />
				</Router>

        )

    }


	_onMessageReceived(event){

		const { _currentScreen, _userAuth, _userLoggedIn, dispatch } = this.props;

		let data;

		if(typeof event.data === 'string')

			data = JSON.parse(event.data);

		else {

			data = event.data;

		}


		if(!data.message) return;

		const { message } = data;

		const { pathname } = message.location;

		const lIndex = pathname.indexOf('/listing/');

		let postName

		if(lIndex > -1){

			postName = pathname.substr(lIndex + 9);

			let paramsIndex = postName.indexOf('/');

			if(paramsIndex){

				postName = postName.substr(0, paramsIndex);

			}

		}


		switch (message.type) {

			case 'loaded':

				if(!_userLoggedIn){

					dispatch(setCurrentPost(postName));
					dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

				}

			break;


			case 'favoriteAd':

				if(postName){

					dispatch(addToFavorites({ token: _userAuth.token, adTitle: postName }));

				}


			break;


			case 'addToCampaign':

				if(postName){

					dispatch(setSelectedSpaceName(postName));

					if(_currentScreen === CURRENT_DISPLAYING_SCREEN.WELCOME){

						dispatch(setDisplayAddToCampaign(true));

					} else {

						dispatch(addToCampaignFromCampaign({
							adTitle: postName,
							token: _userAuth.token
						}));

					}
				}

			break;


			case 'searchPath':

					dispatch(setLastSearchTerm(message.searchPath));

			break;

			default:

		}

	}

}



function _mapStateToProps(state){

	const { currentScreen } = state['base/app'];
	const { userAuth, userData, userLoggedIn } = state['base/auth'];

	return {
		_currentScreen: currentScreen,
		_userAuth: userAuth,
		_userData: userData,
		_userLoggedIn: userLoggedIn
	}

}

export default connect(_mapStateToProps)(App);

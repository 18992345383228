//  @flow

import { ReducerRegistry } from '../redux';

import { SET_CURRENT_POST, SET_CURRENT_SCREEN, SET_LAST_SEARCH_TERM } from './actionTypes';
import { CURRENT_DISPLAYING_SCREEN } from './constants';


const STORE_NAME = 'base/app';

const DEFAULT_STATE = {

	currentPost: '',
	currentScreen: CURRENT_DISPLAYING_SCREEN.WELCOME,
	lastSearchTerm: ''

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {


			case SET_CURRENT_POST:

				return {
					...state,
					currentPost: action.currentPost
				}

        case SET_CURRENT_SCREEN:

            return {
                ...state,
                currentScreen: action.currentScreen
            }

			case SET_LAST_SEARCH_TERM:
				return {
					...state,
					lastSearchTerm: action.lastSearchTerm
				}

        default:
            return state;

    }

});

//	@flow

import { MiddlewareRegistry } from '../redux';

import { login, register, requestPasswordReset, submitNewPassword } from '../api/auth';
import { setCurrentScreen } from '../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../app/constants';

import { setDetailedCampaigns, setEditingCampaignIndex } from '../campaigns';
import { openDialog } from '../dialog';
import { NoticeDialog, setToastMessage, setToastVisible  } from '../notifications';

import { LOGIN, REGISTER_USER, REQUEST_PASSWORD_RESET, SUBMIT_NEW_PASSWORD, USER_LOGOUT } from './actionTypes';
import { setUserAuth, setUserData, setUserLoggedIn } from './actions';


MiddlewareRegistry.register(store => next => action => {

	switch (action.type) {

		case LOGIN:
			return _login(store, next, action);

		case REGISTER_USER:
			return _storeUser(store, next, action);


		case REQUEST_PASSWORD_RESET:
			return _requestPasswordReset(store, next, action);


		case SUBMIT_NEW_PASSWORD:
			return _submitNewPassword(store, next, action);


		case USER_LOGOUT:
			return _userLogout(store, next, action);


		default:
			return(next(action));

	}

});


async function _storeUser(store, next, action){

	const { dispatch } = store;

	const { user } = action;


	try {

		let registerUserResponse = await register(user).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Communication error',
				modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
			}));

		});

		let { err, message } = registerUserResponse.data;

		if(err){

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Registration error',
				modalMessage: `${ err }`
			}));

		}


		if(message){

			dispatch(setToastMessage(message));
			dispatch(setToastVisible(true));

		}

		return next(action);


	} catch(e) {

		console.log(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Communication error',
			modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
		}));

	}

}


async function _login(store, next, action){

	const { dispatch } = store;

	const { user } = action;


	try {

		let loginResponse = await login(user).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Communication error',
				modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
			}));

		});

		let { auth, err, isAdmin, lastname, message, name, token, userId, username } = loginResponse.data;

		if(err || !auth){

			console.log(loginResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error en inicio de sesión',
				modalMessage: `${ err ? err : message }`
			}));

		}



		next(setUserAuth({ userId, token }));

		let _user = {
			username, lastname, name, isAdmin
		}

		let userDataResult = next(setUserData(_user));

		dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.CONTROL_PANEL_USER));

		dispatch(setUserLoggedIn(true));

		return(userDataResult);


	} catch(e) {

		console.log(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Communication error',
			modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
		}));

	}

}



async function _requestPasswordReset(store, next, action){


	const { dispatch } = store;

	const { username } = action;

	try {

		let requestPasswordResetResponse = await requestPasswordReset(username).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Communication error',
				modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
			}));

		});

		let { err } = requestPasswordResetResponse.data;

		if(err){

			dispatch(setToastMessage(`Solicitud enviada.`));
			dispatch(setToastVisible(true));

		} else {

			dispatch(setToastMessage(`Se ha enviado a su correo electrónico un enlace que le permitirá reestablecer su contraseña. Verifique su correo en unos minutos (si no aparece el correo, revise también su sección de Spam).`));
			dispatch(setToastVisible(true));

		}


		return next(action);


	} catch(e) {

		console.log(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Communication error',
			modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
		}));

	}

}


async function _submitNewPassword(store, next, action){

	const { dispatch } = store;

	const { token, password } = action.params;

	try {

		let setUserPasswordResponse = await submitNewPassword(token, password).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Communication error',
				modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
			}));

		});

		let { err } = setUserPasswordResponse.data;

		if(err){

			dispatch(setToastMessage(`Ocurrió un error intentando establecer su nuevo password.`));
			dispatch(setToastVisible(true));

		} else {

			dispatch(setToastMessage(`Su nuevo password ha sido establecido. Inicie sesión.`));
			dispatch(setToastVisible(true));

		}


		return next(action);


	} catch(e) {

		console.log(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Communication error',
			modalMessage: `Couldn't reach our servers. Make sure you have an active Internet connection`
		}));

	}

}



function _userLogout(store, next, action){

	const { dispatch } = store;

	dispatch(setUserAuth({  }));

	dispatch(setUserData({ }));

	dispatch(setDetailedCampaigns([ ]));

	dispatch(setEditingCampaignIndex(undefined));

	dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

	return next(setUserLoggedIn(false));

}

// @flow
import React, { Component } from 'react';

import { Container, Paper, Typography } from '@material-ui/core';
import type { Dispatch } from 'redux';

import { getFavorites, removeFromFavorites } from '../actions';
import { connect } from '../../redux';

import AddToCampaignDialog from './addToCampaignDialog';
import FavoriteCard from './favoriteCard';

type Props = {
	_displayAddToCampaign: Boolean,
	_favorites: Array<Object>,
	_userAuth: Object,
	dispatch: Dispatch
}


class Favorites extends Component<Props> {


	constructor(props){

		super(props);

		this._loadFavorites = this._loadFavorites.bind(this);

	}



	componentDidMount(){

		this._loadFavorites();

	}



	render(){

		const { _displayAddToCampaign, _favorites } = this.props;

		return(

			<Container maxWidth="md" style={{ marginBottom: '1.7rem', marginTop: '1.7rem' }}>

				{
					_favorites.length ?

					_favorites.map((favorite, index) => <FavoriteCard key={ `favorite_${ index }` } favorite={ favorite } token={ this.props._userAuth.token } onRemove={ () => this._removeFromFavorites(favorite.id) }/> )

					:

					<Paper elevation={ 3 } variant="outlined">
						<Typography variant="h5" component="h5" style={{ margin: '2.9rem 1rem' }}>
							No se han agregado espacios a favoritos.
						</Typography>
					</Paper>
				}

				{
					_displayAddToCampaign && <AddToCampaignDialog />
				}

			</Container>

		)

	}



	_loadFavorites(){

		this.props.dispatch(getFavorites(this.props._userAuth.token));

	}


	_removeFromFavorites(favoriteId){

		this.props.dispatch(removeFromFavorites({ token: this.props._userAuth.token, favoriteId: favoriteId }));

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
	const { favorites } = state['base/favorites'];
	const { displayAddToCampaign } = state['base/campaigns'];

	return {
		_displayAddToCampaign: displayAddToCampaign,
		_favorites: favorites,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(Favorites);

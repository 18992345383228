//	@flow

import { CREATE_CAMPAIGN, GET_CAMPAIGNS, GET_CAMPAIGNS_STATUS, GET_DETAILED_CAMPAIGNS_LIST, REMOVE_CAMPAIGN, REMOVE_CONTRACT_FROM_CAMPAIGN, RENAME_CAMPAIGN, REQUEST_QUOTATION, SET_CAMPAIGNS, SET_CAMPAIGNS_STATUS, SET_CURRENT_EDITING_CAMPAIGN, SET_DETAILED_CAMPAIGNS, SET_DISPLAY_ADD_TO_CAMPAIGN, SET_DISPLAYING_NEW_CAMPAIGN_INPUT, SET_EDITING_CAMPAIGN_INDEX, SET_EDITING_CAMPAIGN_SPACES, UPDATE_CONTRACT_STATUS } from './actionTypes';


export function createCampaign(params){

	return {
		type: CREATE_CAMPAIGN,
		params
	}

}


export function getCampaigns(params){

	return {
		type: GET_CAMPAIGNS,
		params
	}

}


export function getCampaignsStatus(token){
	return {
		type: GET_CAMPAIGNS_STATUS,
		token
	}
}


export function getDetailedCampaignsList(token){

	return {
		type: GET_DETAILED_CAMPAIGNS_LIST,
		token
	}

}


export function removeCampaign(params){

	return {
		type: REMOVE_CAMPAIGN,
		params
	}

}


export function removeSpaceFromCampaign(params){

	return {
		type: REMOVE_CONTRACT_FROM_CAMPAIGN,
		params
	}

}


export function renameCampaign(params){

	return {
		type: RENAME_CAMPAIGN,
		params
	}

}


export function requestQuotation(params){
	return {
		type: REQUEST_QUOTATION,
		params
	}
}



export function setCampaigns(campaigns){

	return {
		type: SET_CAMPAIGNS,
		campaigns
	}

}


export function setCampaignsStatus(campaignsStatus){
	return {
		type: SET_CAMPAIGNS_STATUS,
		campaignsStatus
	}
}


export function setCurrentEditingCampaign(currentEditingCampaign){

	return {
		type: SET_CURRENT_EDITING_CAMPAIGN,
		currentEditingCampaign
	}

}


export function setDetailedCampaigns(detailedCampaigns){

	return {
		type: SET_DETAILED_CAMPAIGNS,
		detailedCampaigns
	}

}


export function setDisplayAddToCampaign(displayAddToCampaign){

	return {
		type: SET_DISPLAY_ADD_TO_CAMPAIGN,
		displayAddToCampaign
	}

}


export function setDisplayingNewCampaignInput(isDisplayingNewCampaignInput){
	return {
		type: SET_DISPLAYING_NEW_CAMPAIGN_INPUT,
		isDisplayingNewCampaignInput
	}
}


export function setEditingCampaignIndex(editingCampaignIndex){

	return {
		type: SET_EDITING_CAMPAIGN_INDEX,
		editingCampaignIndex
	}

}



export function setEditingCampaignSpaces(campaign){

	return {
		type: SET_EDITING_CAMPAIGN_SPACES,
		campaign
	}

}


export function updateContractStatus(params){

	return {
		type: UPDATE_CONTRACT_STATUS,
		params
	}

}
